
import BotSelect from '@/components/bot/BotSelect.vue';
import DefaultSidebarLink from '@/components/default-layout/DefaultSidebarLink.vue';
import { AppRoute } from '@/router/routes';
import { Bot, BotDomain, SupportedFeature } from 'ignite360-core';
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AuthMixin } from '@/mixins/AuthMixin';
import { Interaction } from '@jovotech/inbox-core';

@Component({
  name: 'project-sidebar',
  components: { DefaultSidebarLink, BotSelect },
})
export default class ProjectSidebar extends mixins(AuthMixin) {
  unreadConversations = 0;
  get bot(): Bot | undefined {
    return this.$store.state.bots.bots.find((bot: Bot) => bot.id === this.$route.params.id);
  }

  get announcementFeatureEnabled(): boolean {
    return this.bot?.selectedFeatures?.includes(SupportedFeature.Announcement) ?? false;
  }

    get wlcmHomeFeatureEnabled(): boolean {
      return this.bot?.selectedFeatures?.includes(SupportedFeature.WlcmHome) ?? false;
    }

    get weddingFeatureEnabled(): boolean {
      return this.bot?.selectedFeatures?.includes(SupportedFeature.Wedding) ?? false;
    }

  get showAnalytics(): boolean {
    return this.hasAnalyticsViewerRole() && !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain);
  }

  get showIntegrations(): boolean {
    return this.hasEditorRole() && !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain);
  }

  get showContentGeneral(): boolean {
    return !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain ?? '');
  }

  get showContentAnnouncements(): boolean {
    return !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain);
  }

  get showContentQnA(): boolean {
    return !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain);
  }

  get showWlcmHome(): boolean {
    return !!this.bot && [BotDomain.WlcmHome].includes(this.bot?.domain);
  }

   get showWedding(): boolean {
    return !!this.bot && [BotDomain.Wedding].includes(this.bot?.domain);
  }

  get showContentDomain(): boolean {
    return !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain);
  }

  get showContent(): boolean {
    return this.hasEditorRole() && !!this.bot && ![BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain);
  }

  async mounted() {
    if (!this.$route.params.id) {
      return;
    }

    try {
      
      await this.$store.dispatch('inbox/fetchCheckedConversations', {
        projectId: this.$route.params.id,
      });

      await this.$store.dispatch('inbox/fetchConversations', {
        projectId: this.$route.params.id,
      });

      this.updateUnreadConversations();
    } catch (e) {
      console.log(e);
    }
  }

  get checkConversations() {
    return this.$store.state.inbox.checkedConversations;
  }

  @Watch('checkConversations')
  watchCheckedConversations() {
     if ( !!this.bot && [BotDomain.WlcmHome, BotDomain.Wedding].includes(this.bot?.domain)) {
      return;
    }
    this.updateUnreadConversations();
  }

  updateUnreadConversations() {
    this.unreadConversations = 0;
    for (let i = 0; i < this.$store.state.inbox.conversations.length; i++) {
      const conversation: Interaction = this.$store.state.inbox.conversations[i];

      if (conversation.logs.length > 0) {
        const lastLog = conversation.logs[conversation.logs.length - 1];

        const checkedConversation =
          this.checkConversations[lastLog.projectId + '-' + lastLog.userId];

        if (checkedConversation && checkedConversation < new Date()) {
          continue;
        }

        this.unreadConversations++;
      }
    }
  }

  onBotSelect(id: Bot['id']) {
    // TODO update logic for domain-change
    if (this.$route.name?.startsWith('project.')) {
      const selectedBot = this.$store.state.bots.bots.find((bot: Bot) => bot.id === id);
      if (!selectedBot) {
        return;
      }
      let routeName = this.$route.name;
      if (selectedBot.domain !== this.bot?.domain) {
        routeName =
          selectedBot.domain === BotDomain.Restaurant
            ? AppRoute.ProjectRestaurantMenu
            : AppRoute.ProjectContentRealEstate;
      }
      this.$router.push({ name: routeName, params: { ...this.$route.params, id } });
    } else {
      this.$router.push({ name: AppRoute.ProjectOverview, params: { id } });
    }
  }
}
