
import { Component, Vue } from 'vue-property-decorator';
import { AuthMixin } from '@/mixins/AuthMixin';
import { mixins } from 'vue-class-component';
import { tenantId } from '@/constants';

@Component({
  name: 'default-navbar-desktop',
})
export default class DefaultNavbarDesktop extends mixins(AuthMixin) {
  
  
  get hasChains() {
    return tenantId === 'ignite360';
  }
}
