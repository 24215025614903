import { AppRoute } from '@/router/routes';
import { Bot, SupportedPlatform } from 'ignite360-core';

export type RemoveNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
}

export enum ButtonSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export interface Metadata {
  [key: string]: unknown;

  label: string;
  image?: string;
  description?: string;
  value?: string;
  routeName?: AppRoute | string;
}

export interface ServiceMetadata extends Metadata {
  isConnected: (bot: Bot) => boolean;
  isDisabled?: (bot: Bot) => boolean;
  status?: (bot: Bot) => string | { class?: string; text?: string } | undefined;
}

export interface PlatformMetadata extends ServiceMetadata {
  value: SupportedPlatform;
}

export interface SelectOption {
  [key: string]: unknown;

  value: string | number;
  label: string;
  disabled?: boolean;
  image?: string;
}

export enum NotificationType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export type NotificationTypeValues = keyof typeof NotificationType;

export interface NotificationData {
  id?: string | number;
  type: NotificationType | NotificationTypeValues;
  title: string;
  description?: string;
  duration?: number;
}

export * from './bot';
export * from './chain';
export * from './wlcm-home';
export * from './wedding';

