import axios, { AxiosRequestConfig } from 'axios';
import { tenant, tenantId } from '@/constants';

let BACKEND_URL;
if (process.env.NODE_ENV === 'development') {
  BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
}

export const BASE_URL = BACKEND_URL || tenant.apiBaseUrl;
export const BOTS_BASE_URL = `${BASE_URL}/bots`;
export const CHAINS_BASE_URL = `${BASE_URL}/chains`;
export const INBOX_BASE_URL = `${BASE_URL}/inbox`;
export const S3_BUCKET = process.env.VUE_APP_S3_BUCKET;
export const S3_REGION = process.env.VUE_APP_S3_REGION;


axios.defaults.withCredentials = true;

export class Api {
  static async login(model: { password: string; email: string }) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/auth/login`,
      data: model,
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async logout() {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/auth/logout`,
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async forgotPassword(model: { email: string }) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/auth/forgot-password`,
      data: model,
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async resetPassword(model: { code: string; password: string }) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/auth/reset-password`,
      data: model,
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async acceptInvitation(model: { code: string; email: string; password: string }) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/auth/accept-invitation`,
      data: model,
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async user() {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/auth/user`,
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static setAuthorization(authorization: string | null) {
    this.authorization = authorization;
  }

  static finalizeConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    if (!config.headers) {
      config.headers = {};
    }
    if (this.authorization) {
      if (this.authorization) {
        config.headers.Authorization = `Basic ${this.authorization}`;
      }
      config.withCredentials = true;
    }
    config.headers['X-Tenant'] = tenantId;

    return config;
  }

  private static authorization: string | null = null;
}

export * from './BotApi';
export * from './LinkedInApi';
export * from './GoogleMyBusinessApi';
export * from './DomainAuApi';
export * from './FacebookApi';
export * from './YextApi';
export * from './errors';
