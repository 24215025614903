
import BotSelect from '@/components/bot/BotSelect.vue';
import { Bot, CreateBot, UserRole, CreateCollaborationInvitation, UserToBot, BotDomain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BotSettings } from '@/types';
import { BotMixin } from '@/mixins/BotMixin';
import { mixins } from 'vue-class-component';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotApi } from '@/api';

@Component({
  name: 'invite-collaborator-modal',
  components: { BotSelect },
})
export default class InviteCollaboratorModal extends mixins(BotMixin) {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;
  isSubmitting = false;

  model = this.resetValue();


  get options(): { label: string; value: UserRole }[] {
    const options = [
      { label: 'Admin', value: UserRole.Admin },
      { label: 'Editor', value: UserRole.Editor },
    ];

    if (![BotDomain.Wedding, BotDomain.WlcmHome].includes(this.bot.domain)) {
      options.push({ label: 'Analytics Viewer', value: UserRole.AnalyticsViewer });
    }

    return options;
  }

  get collaborators(): UserToBot[] {
    return this.bot.userToBot || [];
  }
  // simple validation anystring@anystring.anystring
  get canSend() {
    return /\S+@\S+\.\S+/.test(this.model.email);
  }

  async onSubmit() {
    const data: CreateCollaborationInvitation = {
      ...this.model,
    };
    this.isSubmitting = true;
    try {
      const invite = await BotApi.sendCollaborationInvite(this.bot.id, data);

      const collaborators = [...this.collaborators, invite];

      this.$store.commit('bots/updateBot', {
        ...this.bot,
        userToBot: collaborators,
      });

      this.$notify.success('Successfully invited');
      this.$emit('update:show', false);
      this.reset();
    } catch (e) {
      this.$notify.error({
        title: 'Sending invites failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  onInput(key: string, value: any) {
    this.model = { ...this.model, [key]: value };
  }

  reset() {
    this.model = this.resetValue();
  }
  resetValue() {
    return {
      email: '',
      role: UserRole.Editor,
    };
  }
}
