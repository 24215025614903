
import BotFeaturesCheckboxGroup from '@/components/bot/BotFeaturesCheckboxGroup.vue';
import { BotSettings, SelectOption } from '@/types';
import { BotDomain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import InviteCollaboratorModal from '@/components/bot/InviteCollaboratorModal.vue';
import { AuthMixin } from '@/mixins/AuthMixin';
import { mixins } from 'vue-class-component';
import { tenantId } from '@/constants';

@Component({
  name: 'bot-form',
  components: { InviteCollaboratorModal, BotFeaturesCheckboxGroup },
})
export default class BotForm extends mixins(AuthMixin) {
  @Prop({ required: true, type: Object })
  readonly value!: BotSettings;

  showInviteCollaboratorModal = false;


  onInput(key: keyof BotSettings, value: any) {
    this.$emit('input', { ...this.value, [key]: value });
  }

  get domainSelectOptions(): SelectOption[] {
    const options = [
    ];

    if (tenantId === 'ignite360') {
      options.push( { value: BotDomain.Restaurant, label: 'Restaurant', disabled: false });
      options.push({ value: BotDomain.RealEstate, label: 'Real Estate', disabled: false });
    }

    // if (tenantId === 'wedding') {
      options.push({ value: BotDomain.Wedding, label: 'Wedding', disabled: false });
    // }
    // if (tenantId === 'wlcmHome') {
      options.push({ value: BotDomain.WlcmHome, label: 'WLCM Home', disabled: false });
    // }

    return options;
  }

  get showTimezoneInput() {
    return [BotDomain.RealEstate, BotDomain.Restaurant].includes(this.value.domain);
  }

  get showEnvironmentInput() {
    return [BotDomain.RealEstate, BotDomain.Restaurant].includes(this.value.domain);
  }

  get showDomainInput() {
    return true; 
    // return ['tanis', 'ignite360'].includes(tenantId);
  }

  get showContentModulesInput() {
    return true; 
    // return ['tanis', 'ignite360'].includes(tenantId);
  }



  get environmentSelectOptions(): SelectOption[] {
    return [
      {
        value: 'staging',
        label: 'Staging',
      },
      {
        value: 'production',
        label: 'Production',
      },
    ];
  }
}
