import { PUBLIC_ROUTES } from '@/constants';
import { AppRoute } from '@/router/routes';
import Chains from '@/views/Chains.vue';
import Login from '@/views/Login.vue';
import NotFound from '@/views/NotFound.vue';
import Projects from '@/views/Projects.vue';
import PublicSocialLogin from '@/views/PublicSocialLogin.vue';
import Vue from 'vue';
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router';
import store from '../store';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import AcceptInvitation from '@/views/AcceptInvitation.vue';
import { UserRole } from 'ignite360-core';

Vue.use(VueRouter);

const mustBeAuthenticated: NavigationGuard = (to, from, next) => {
  if (!PUBLIC_ROUTES.includes(to.name as AppRoute) && !store.getters['auth/isAuthenticated']) {
    next({ name: AppRoute.Login });
  } else {
    next();
  }
};

const mustBeSuperAdmin: NavigationGuard = (to, from, next) => {
  if (store.state.auth.user?.role !== UserRole.SuperAdmin) {
    next({ name: AppRoute.Login });
  } else {
    next();
  }
};

const mustBeAdmin: NavigationGuard = (to, from, next) => {
  if (store.state.auth.user?.role !== UserRole.Admin) {
    next({ name: AppRoute.Login });
  } else {
    next();
  }
};

const mustBeUnauthenticated: NavigationGuard = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    if (to.params?.backToLogin) {
      next();
    } else if (![AppRoute.AcceptInvitation, AppRoute.Login].includes(to.name as AppRoute)) {
      next({ name: AppRoute.Projects });
    } else {
      next();
    }
  } else {
    next();
  }
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    alias: '/login',
    name: AppRoute.Login,
    meta: {
      layout: 'blank',
    },
    component: Login,
    beforeEnter: mustBeUnauthenticated,
  },
  {
    path: '/forgot-password',
    name: AppRoute.ForgotPassword,
    meta: {
      layout: 'blank',
    },
    component: ForgotPassword,
    beforeEnter: mustBeUnauthenticated,
  },
  {
    path: '/reset-password',
    name: AppRoute.ResetPassword,
    meta: {
      layout: 'blank',
    },
    component: ResetPassword,
    beforeEnter: mustBeUnauthenticated,
  },
  {
    path: '/accept-invitation',
    name: AppRoute.AcceptInvitation,
    meta: {
      layout: 'blank',
    },
    component: AcceptInvitation,
    beforeEnter: mustBeUnauthenticated,
  },
  {
    path: '/projects',
    name: AppRoute.Projects,
    component: Projects,
    children: [
      {
        path: ':id/overview',
        alias: ':id',
        name: AppRoute.ProjectOverview,
        meta: {
          name: '',
        },
        component: () =>
          import(/* webpackChunkName: "project-overview" */ '@/views/ProjectOverview.vue'),
      },
      {
        path: ':id/content/general',
        name: AppRoute.ProjectContentGeneral,
        meta: {
          name: 'General Content',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-content-general" */ '@/views/ProjectContentGeneral.vue'
          ),
        children: [
          {
            path: 'data-sources/yext',
            name: AppRoute.ProjectDataSourceYext,
            component: () =>
              import(
                /* webpackChunkName: "project-datasource-yext" */ '@/views/ProjectDatasourceYext.vue'
              ),
          },
        ],
      },

      {
        path: ':id/content/messages',
        name: AppRoute.ProjectContentMessages,
        component: () =>
          import(
            /* webpackChunkName: "project-content-messages" */ '@/views/ProjectContentMessages.vue'
          ),
      },
      {
        path: ':id/content/restaurant',
        name: AppRoute.ProjectContentRestaurant,
        component: () =>
          import(
            /* webpackChunkName: "project-content-restaurant" */ '@/views/ProjectContentRestaurant.vue'
          ),
        children: [
          {
            path: 'menu',
            alias: '',
            name: AppRoute.ProjectRestaurantMenu,
            component: () =>
              import(
                /* webpackChunkName: "project-content-restaurant-menu" */ '@/views/ProjectRestaurantMenu.vue'
              ),
          },
          {
            path: 'booking',
            name: AppRoute.ProjectRestaurantBooking,
            component: () =>
              import(
                /* webpackChunkName: "project-content-restaurant-booking" */ '@/views/ProjectRestaurantBooking.vue'
              ),
          },
          {
            path: 'order',
            name: AppRoute.ProjectRestaurantOrder,
            component: () =>
              import(
                /* webpackChunkName: "project-content-restaurant-order" */ '@/views/ProjectRestaurantOrder.vue'
              ),
          },
        ],
      },
      {
        path: ':id/content/real-estate',
        name: AppRoute.ProjectContentRealEstate,
        component: () =>
          import(
            /* webpackChunkName: "project-content-real-estate" */ '@/views/ProjectContentRealEstate.vue'
          ),
        children: [
          {
            path: 'listings',
            name: AppRoute.ProjectRealEstateListings,
            component: () =>
              import(
                /* webpackChunkName: "project-content-real-estate-listings" */ '@/views/ProjectRealEstateListings.vue'
              ),
          },
          {
            path: 'domain-com-au',
            name: AppRoute.ProjectRealEstateDomainComAu,
            meta: {
              name: 'Domain.com.au',
            },
            component: () =>
              import(
                /* webpackChunkName: "project-content-real-estate-domain-com-au" */ '@/views/ProjectRealEstateDomainComAu.vue'
              ),
          },
          {
            path: 'agentbox',
            name: AppRoute.ProjectRealEstateAgentbox,
            component: () =>
              import(
                /* webpackChunkName: "project-content-real-estate-agentbox" */ '@/views/ProjectRealEstateAgentbox.vue'
              ),
          },
          {
            path: 'eagle-crm',
            name: AppRoute.ProjectRealEstateEagleCRM,
            meta: {
              name: 'Eagle CRM',
            },
            component: () =>
              import(
                /* webpackChunkName: "project-content-real-estate-eagle-crm" */ '@/views/ProjectRealEstateEagleCRM.vue'
              ),
          },
          {
            path: 'manual-entry',
            name: AppRoute.ProjectRealEstateManualEntry,
            meta: {
              name: 'Manual Entry',
            },
            component: () =>
              import(
                /* webpackChunkName: "project-content-real-estate-eagle-crm" */ '@/views/ProjectRealEstateManualEntry.vue'
              ),
          },
        ],
      },
      {
        path: ':id/content/announcements',
        name: AppRoute.ProjectContentAnnouncements,
        component: () =>
          import(
            /* webpackChunkName: "project-content-announcements" */ '@/views/ProjectContentAnnouncements.vue'
          ),
      },
      {
        path: ':id/content/questions-and-answers',
        name: AppRoute.ProjectContentQnA,
        meta: {
          name: 'Q&A',
        },
        component: () =>
          import(/* webpackChunkName: "project-content-qna" */ '@/views/ProjectContentQnA.vue'),
      },
      {
        path: ':id/wlcm-home/campaigns',
        name: AppRoute.ProjectWlcmHomeCampaigns,
        meta: {
          name: 'Campaigns',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-wlcm-home-campaigns" */ '@/views/ProjectWlcmHomeCampaigns.vue'
          ),
      },
      {
        path: ':id/wlcm-home/campaigns/:campaignId',
        name: AppRoute.ProjectWlcmHomeCampaignOverview,
        meta: {
          name: 'Campaign Overview',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-wlcm-home-campaign-overview" */ '@/views/ProjectWlcmHomeCampaignOverview.vue'
          ),
      },
      {
        path: ':id/wedding/events',
        name: AppRoute.ProjectWeddingEvents,
        meta: {
          name: 'Weddings',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-wedding-events" */ '@/views/ProjectWeddingEvents.vue'
          ),
      },
      {
        path: ':id/wedding/events/:eventId',
        name: AppRoute.ProjectWeddingEventOverview,
        meta: {
          name: 'Event Overview',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-wedding-event-overview" */ '@/views/ProjectWeddingEventOverview.vue'
          ),
      },
      {
        path: ':id/integrations/voice-and-chat',
        name: AppRoute.ProjectIntegrationConversation,
        meta: {
          name: 'Voice & Chat Integrations',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-integrations-conversation" */ '@/views/ProjectIntegrationConversation.vue'
          ),
        children: [
          {
            path: 'platforms/amazon-alexa',
            name: AppRoute.ProjectPlatformAmazonAlexa,
            component: () =>
              import(
                /* webpackChunkName: "project-platform-amazon-alexa" */ '@/views/ProjectPlatformAmazonAlexa.vue'
              ),
          },
          {
            path: 'platforms/facebook-messenger',
            name: AppRoute.ProjectPlatformFacebookMessenger,
            component: () =>
              import(
                /* webpackChunkName: "project-platform-facebook-messenger" */ '@/views/ProjectPlatformFacebookMessenger.vue'
              ),
          },
          {
            path: 'platforms/google-assistant',
            name: AppRoute.ProjectPlatformGoogleAssistant,
            component: () =>
              import(
                /* webpackChunkName: "project-platform-google-assistant" */ '@/views/ProjectPlatformGoogleAssistant.vue'
              ),
          },
          {
            path: 'platforms/google-business-messages',
            name: AppRoute.ProjectPlatformGoogleBusinessMessages,
            component: () =>
              import(
                /* webpackChunkName: "project-platform-google-business-messages" */ '@/views/ProjectPlatformGoogleBusinessMessages.vue'
              ),
          },
          {
            path: 'platforms/instagram',
            name: AppRoute.ProjectPlatformInstagram,
            component: () =>
              import(
                /* webpackChunkName: "project-platform-instagram" */ '@/views/ProjectPlatformInstagram.vue'
              ),
          },
        ],
      },
      {
        path: ':id/integrations/social-posting',
        name: AppRoute.ProjectIntegrationSocialPosting,
        meta: {
          name: 'Social Posting Integrations',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-integrations-social" */ '@/views/ProjectIntegrationSocialPosting.vue'
          ),
        children: [
          {
            path: 'platforms/facebook',
            name: AppRoute.ProjectSocialPlatformFacebook,
            component: () =>
              import(
                /* webpackChunkName: "project-social-platform-facebook" */ '@/views/ProjectSocialPlatformFacebook.vue'
              ),
          },
          {
            path: 'platforms/google-my-business',
            name: AppRoute.ProjectSocialPlatformGoogleMyBusiness,
            component: () =>
              import(
                /* webpackChunkName: "project-social-platform-google-my-business" */ '@/views/ProjectSocialPlatformGoogleMyBusiness.vue'
              ),
          },
          {
            path: 'platforms/linked-in',
            name: AppRoute.ProjectSocialPlatformLinkedIn,
            meta: {
              name: 'LinkedIn',
            },
            component: () =>
              import(
                /* webpackChunkName: "project-social-platform-linked-in" */ '@/views/ProjectSocialPlatformLinkedIn.vue'
              ),
          },
          {
            path: 'platforms/instagram',
            name: AppRoute.ProjectSocialPlatformInstagram,
            meta: {
              name: 'Instagram',
            },
            component: () =>
              import(
                /* webpackChunkName: "project-social-platform-linked-in" */ '@/views/ProjectSocialPlatformInstagram.vue'
              ),
          },
        ],
      },

      {
        path: ':id/analytics/inbox',
        name: AppRoute.ProjectAnalyticsInbox,
        meta: {
          name: 'Inbox',
        },
        component: () =>
          import(
            /* webpackChunkName: "project-analytics-inbox" */ '@/views/ProjectAnalyticsInbox.vue'
          ),
        children: [
          {
            name: AppRoute.ProjectAnalyticsInboxConversation,
            path: ':userId',
            component: () =>
              import(
                /* webpackChunkName: "project-analytics-inbox" */ '@/views/ProjectAnalyticsInbox.vue'
              ),
          },
          {
            name: AppRoute.ProjectAnalyticsInboxConversationSessions,
            path: ':userId/sessions',
            component: () =>
              import(
                /* webpackChunkName: "project-analytics-inbox" */ '@/views/ProjectAnalyticsInbox.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/social-login/:id',
    name: AppRoute.PublicSocialLogin,
    meta: {
      layout: 'blank',
      name: 'Customer Social Login',
    },
    component: PublicSocialLogin,
    children: [
      {
        path: 'facebook',
        name: AppRoute.PublicSocialLoginFacebook,
        meta: {
          layout: 'blank',
        },
        component: () =>
          import(
            /* webpackChunkName: "social-login-facebook" */ '@/views/PublicSocialLoginFacebook.vue'
          ),
      },
      {
        path: 'google-my-business',
        name: AppRoute.PublicSocialLoginGoogleMyBusiness,
        meta: {
          layout: 'blank',
        },
        component: () =>
          import(
            /* webpackChunkName: "social-login-google-my-business" */ '@/views/PublicSocialLoginGoogleMyBusiness.vue'
          ),
      },
      {
        path: 'instagram',
        name: AppRoute.PublicSocialLoginInstagram,
        meta: {
          layout: 'blank',
        },
        component: () =>
          import(
            /* webpackChunkName: "social-login-instagram" */ '@/views/PublicSocialLoginInstagram.vue'
          ),
      },
      {
        path: 'linked-in',
        name: AppRoute.PublicSocialLoginLinkedIn,
        meta: {
          layout: 'blank',
        },
        component: () =>
          import(
            /* webpackChunkName: "social-login-linked-in" */ '@/views/PublicSocialLoginLinkedIn.vue'
          ),
      },
    ],
  },
  {
    path: '/chains',
    name: AppRoute.Chains,
    component: Chains,
    beforeEnter: mustBeSuperAdmin,
    children: [
      {
        path: ':id/overview',
        alias: ':id',
        name: AppRoute.ChainOverview,
        meta: {
          name: '',
        },
        component: () =>
          import(/* webpackChunkName: "chain-overview" */ '@/views/ChainOverview.vue'),
      },
      {
        path: ':id/content/announcements',
        name: AppRoute.ChainContentAnnouncements,
        component: () =>
          import(
            /* webpackChunkName: "chain-content-announcements" */ '@/views/ChainContentAnnouncements.vue'
          ),
      },
    ],
  },
  {
    path: '/public/wlcm-home/campaigns/:id',
    name: AppRoute.PublicWlcmHomeCampaign,
    meta: {
      layout: 'blank',
    },
    component: () =>
      import(
        /* webpackChunkName: "public-wlcm-home-campaign" */ '@/views/PublicWlcmHomeCampaign.vue'
      ),
  },
  {
    path: '/public/wedding/events/:id',
    name: AppRoute.PublicWeddingEvent,
    meta: {
      layout: 'blank',
    },
    component: () =>
      import(
        /* webpackChunkName: "public-wedding-event" */ '@/views/PublicWeddingEvent.vue'
      ),
  },
  {
    path: '/wedding/:slug',
    name: AppRoute.PublicWeddingEvent,
    meta: {
      layout: 'blank',
    },
    component: () =>
      import(
        /* webpackChunkName: "public-wedding-event" */ '@/views/PublicWeddingEvent.vue'
      ),
  },
  {
    path: '/home/:slug',
    name: AppRoute.PublicWlcmHomeCampaign,
    meta: {
      layout: 'blank',
    },
    component: () =>
      import(
        /* webpackChunkName: "public-wlcm-home-campaign" */ '@/views/PublicWlcmHomeCampaign.vue'
      ),
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(mustBeAuthenticated);

export default router;
